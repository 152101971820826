import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CoreUiModule } from '@lla-platform/core/core-ui'
import { MainHeaderComponent } from './components/main-header/main-header.component'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component'
import { MatTableModule } from '@angular/material/table'
import { NgxPaginationModule } from 'ngx-pagination'
import { MatSortModule } from '@angular/material/sort'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MainMenuComponent } from './components/main-menu/main-menu.component'
import { TableHeaderComponent } from './components/table-header/table-header.component'
import { AddBookmarkDialogComponent } from './components/add-bookmark-dialog/add-bookmark-dialog.component'
import { InvoiceInfoComponent } from './components/invoice-info/invoice-info.component'
import { LineChartComponent } from './components/line-chart/line-chart.component'
import { BarChartComponent } from './components/bar-chart/bar-chart.component'
import { ShopsSelectorComponent } from './components/shops-selector/shops-selector.component'
import { InvoiceInfoDialogComponent } from './components/invoice-info-dialog/invoice-info-dialog.component'
import { ClipboardModule } from 'ngx-clipboard'
import { TimePeriodFilterComponent } from './components/time-period-filter/time-period-filter.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component'
import { RangeDatePickerFilterComponent } from './components/range-date-picker-filter/range-date-picker-filter.component'
import { PillComponent } from './components/pill/pill.component'
import { TableFilterComponent } from './widgets/table-filter/table-filter.component'
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component'
import { TableFiltersListComponent } from './widgets/table-filter/components/table-filters-list/table-filters-list.component'
import { TableFiltersHeaderComponent } from './widgets/table-filter/components/table-filters-header/table-filters-header.component'
import { InvoiceInfoCellComponent } from './components/invoice-info-cell/invoice-info-cell.component'
import { PageTitleComponent } from './components/page-title/page-title.component'
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component'
import { ItemsSelectorComponent } from './components/items-selector/items-selector.component'
import { ItemsSelectorFilterComponent } from './components/items-selector-filter/items-selector-filter.component'
import { ConfirmSimpleModeDialogComponent } from './widgets/table-filter/components/confirm-simple-mode-dialog/confirm-simple-mode-dialog.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreUiModule,
    RouterModule,
    SvgIconsModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    NgxPaginationModule,
    MatSortModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule
  ],
  declarations: [
    AddBookmarkDialogComponent,
    MainHeaderComponent,
    ProfileLayoutComponent,
    MainMenuComponent,
    TableHeaderComponent,
    InvoiceInfoComponent,
    LineChartComponent,
    BarChartComponent,
    ShopsSelectorComponent,
    InvoiceInfoDialogComponent,
    TimePeriodFilterComponent,
    RangeDatePickerComponent,
    RangeDatePickerFilterComponent,
    PillComponent,
    DropdownMenuComponent,
    ConfirmSimpleModeDialogComponent,
    TableFiltersListComponent,
    TableFiltersHeaderComponent,
    TableFilterComponent,
    InvoiceInfoCellComponent,
    PageTitleComponent,
    FilterDialogComponent,
    ItemsSelectorComponent,
    ItemsSelectorFilterComponent
  ],
  exports: [
    MainHeaderComponent,
    ProfileLayoutComponent,
    MainMenuComponent,
    TableHeaderComponent,
    InvoiceInfoComponent,
    LineChartComponent,
    BarChartComponent,
    ShopsSelectorComponent,
    InvoiceInfoDialogComponent,
    TimePeriodFilterComponent,
    RangeDatePickerComponent,
    RangeDatePickerFilterComponent,
    PillComponent,
    DropdownMenuComponent,
    TableFiltersListComponent,
    TableFiltersHeaderComponent,
    TableFilterComponent,
    InvoiceInfoCellComponent,
    PageTitleComponent,
    FilterDialogComponent,
    ItemsSelectorComponent,
    ItemsSelectorFilterComponent
  ],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }]
})
export class CoreFeatureModule {}
