import { llaPlatformAddCircleIcon } from './add-circle'
import { llaPlatformAddIcon } from './add'
import { llaPlatformArrowBackIcon } from './arrow-back'
import { llaPlatformArrowDownFullIcon } from './arrow-down-full'
import { llaPlatformArrowDownSimpleIcon } from './arrow-down-simple'
import { llaPlatformArrowDownIcon } from './arrow-down'
import { llaPlatformArrowLeftIcon } from './arrow-left'
import { llaPlatformArrowNorthEastIcon } from './arrow-north-east'
import { llaPlatformArrowRightIcon } from './arrow-right'
import { llaPlatformArrowSouthEastIcon } from './arrow-south-east'
import { llaPlatformArrowUpFullIcon } from './arrow-up-full'
import { llaPlatformArrowUpIcon } from './arrow-up'
import { llaPlatformAutoGraphIcon } from './auto-graph'
import { llaPlatformBallotIcon } from './ballot'
import { llaPlatformBarChartIcon } from './bar-chart'
import { llaPlatformBellOffIcon } from './bell-off'
import { llaPlatformBellIcon } from './bell'
import { llaPlatformBookmarkIcon } from './bookmark'
import { llaPlatformBuildingIcon } from './building'
import { llaPlatformCalendarLightIcon } from './calendar-light'
import { llaPlatformCalendarIcon } from './calendar'
import { llaPlatformCarCategoryIcon } from './car-category'
import { llaPlatformCarIcon } from './car'
import { llaPlatformChartBarIcon } from './chart-bar'
import { llaPlatformChartInsightIcon } from './chart-insight'
import { llaPlatformChartIcon } from './chart'
import { llaPlatformCheckCircleIcon } from './check-circle'
import { llaPlatformCheckListIcon } from './check-list'
import { llaPlatformCircleBorderdIcon } from './circle-borderd'
import { llaPlatformCircleEmptyIcon } from './circle-empty'
import { llaPlatformCircleFillIcon } from './circle-fill'
import { llaPlatformCloseCircleIcon } from './close-circle'
import { llaPlatformCloseLightIcon } from './close-light'
import { llaPlatformCloseIcon } from './close'
import { llaPlatformCloudDoneIcon } from './cloud-done'
import { llaPlatformColumnsIcon } from './columns'
import { llaPlatformCongratulationsIcon } from './congratulations'
import { llaPlatformCopyIcon } from './copy'
import { llaPlatformDashboardIcon } from './dashboard'
import { llaPlatformDotsHorizontalIcon } from './dots-horizontal'
import { llaPlatformDownloadCloudIcon } from './download-cloud'
import { llaPlatformDownloadIcon } from './download'
import { llaPlatformEditIcon } from './edit'
import { llaPlatformExitIcon } from './exit'
import { llaPlatformEyeLightIcon } from './eye-light'
import { llaPlatformEyeIcon } from './eye'
import { llaPlatformFileCopyIcon } from './file-copy'
import { llaPlatformFileTextIcon } from './file-text'
import { llaPlatformFileIcon } from './file'
import { llaPlatformFilterIcon } from './filter'
import { llaPlatformFiltersIcon } from './filters'
import { llaPlatformFlagPriorityFillIcon } from './flag-priority-fill'
import { llaPlatformFlagPriorityIcon } from './flag-priority'
import { llaPlatformFlashOnIcon } from './flash-on'
import { llaPlatformGarageIcon } from './garage'
import { llaPlatformGridIcon } from './grid'
import { llaPlatformHourglassIcon } from './hourglass'
import { llaPlatformImageIcon } from './image'
import { llaPlatformInfoCircleIcon } from './info-circle'
import { llaPlatformInsightIcon } from './insight'
import { llaPlatformInventoryIcon } from './inventory'
import { llaPlatformLinkBreakIcon } from './link-break'
import { llaPlatformListIcon } from './list'
import { llaPlatformLockIcon } from './lock'
import { llaPlatformMailOpenIcon } from './mail-open'
import { llaPlatformMenuIcon } from './menu'
import { llaPlatformMessageIcon } from './message'
import { llaPlatformMinusIcon } from './minus'
import { llaPlatformMonetizationOnIcon } from './monetization-on'
import { llaPlatformNotVerifiedIcon } from './not-verified'
import { llaPlatformPageLandscapeIcon } from './page-landscape'
import { llaPlatformPatrolRefuleIcon } from './patrol-refule'
import { llaPlatformPdfIcon } from './pdf'
import { llaPlatformPhoneCallHangUpIcon } from './phone-call-hang-up'
import { llaPlatformPhoneIcon } from './phone'
import { llaPlatformPlusIcon } from './plus'
import { llaPlatformPriceTagIcon } from './price-tag'
import { llaPlatformPrinterIcon } from './printer'
import { llaPlatformReceiveMoneyIcon } from './receive-money'
import { llaPlatformRedoIcon } from './redo'
import { llaPlatformRegionIcon } from './region'
import { llaPlatformSearchIcon } from './search'
import { llaPlatformSettingsIcon } from './settings'
import { llaPlatformShopStoreIcon } from './shop-store'
import { llaPlatformSidebarLeftIcon } from './sidebar-left'
import { llaPlatformSidebarMiddleIcon } from './sidebar-middle'
import { llaPlatformStackIcon } from './stack'
import { llaPlatformStarOutlineIcon } from './star-outline'
import { llaPlatformStarSolidIcon } from './star-solid'
import { llaPlatformTagAddIcon } from './tag-add'
import { llaPlatformTargetIcon } from './target'
import { llaPlatformTickIcon } from './tick'
import { llaPlatformTrashIcon } from './trash'
import { llaPlatformTreeIcon } from './tree'
import { llaPlatformTrendIcon } from './trend'
import { llaPlatformUserCircleIcon } from './user-circle'
import { llaPlatformUserPlusIcon } from './user-plus'
import { llaPlatformUserIcon } from './user'
import { llaPlatformUsersCircleIcon } from './users-circle'
import { llaPlatformUsersIcon } from './users'
import { llaPlatformVerifiedIcon } from './verified'
import { llaPlatformVerticalAlignCenterTriangleIcon } from './vertical-align-center-triangle'
import { llaPlatformWarningDiamondIcon } from './warning-diamond'
import { llaPlatformWarningRoundIcon } from './warning-round'
import { llaPlatformWheelIcon } from './wheel'
export const commonIcons = [
  llaPlatformAddCircleIcon,
  llaPlatformAddIcon,
  llaPlatformArrowBackIcon,
  llaPlatformArrowDownFullIcon,
  llaPlatformArrowDownSimpleIcon,
  llaPlatformArrowDownIcon,
  llaPlatformArrowLeftIcon,
  llaPlatformArrowNorthEastIcon,
  llaPlatformArrowRightIcon,
  llaPlatformArrowSouthEastIcon,
  llaPlatformArrowUpFullIcon,
  llaPlatformArrowUpIcon,
  llaPlatformAutoGraphIcon,
  llaPlatformBallotIcon,
  llaPlatformBarChartIcon,
  llaPlatformBellOffIcon,
  llaPlatformBellIcon,
  llaPlatformBookmarkIcon,
  llaPlatformBuildingIcon,
  llaPlatformCalendarLightIcon,
  llaPlatformCalendarIcon,
  llaPlatformCarCategoryIcon,
  llaPlatformCarIcon,
  llaPlatformChartBarIcon,
  llaPlatformChartInsightIcon,
  llaPlatformChartIcon,
  llaPlatformCheckCircleIcon,
  llaPlatformCheckListIcon,
  llaPlatformCircleBorderdIcon,
  llaPlatformCircleEmptyIcon,
  llaPlatformCircleFillIcon,
  llaPlatformCloseCircleIcon,
  llaPlatformCloseLightIcon,
  llaPlatformCloseIcon,
  llaPlatformCloudDoneIcon,
  llaPlatformColumnsIcon,
  llaPlatformCongratulationsIcon,
  llaPlatformCopyIcon,
  llaPlatformDashboardIcon,
  llaPlatformDotsHorizontalIcon,
  llaPlatformDownloadCloudIcon,
  llaPlatformDownloadIcon,
  llaPlatformEditIcon,
  llaPlatformExitIcon,
  llaPlatformEyeLightIcon,
  llaPlatformEyeIcon,
  llaPlatformFileCopyIcon,
  llaPlatformFileTextIcon,
  llaPlatformFileIcon,
  llaPlatformFilterIcon,
  llaPlatformFiltersIcon,
  llaPlatformFlagPriorityFillIcon,
  llaPlatformFlagPriorityIcon,
  llaPlatformFlashOnIcon,
  llaPlatformGarageIcon,
  llaPlatformGridIcon,
  llaPlatformHourglassIcon,
  llaPlatformImageIcon,
  llaPlatformInfoCircleIcon,
  llaPlatformInsightIcon,
  llaPlatformInventoryIcon,
  llaPlatformLinkBreakIcon,
  llaPlatformListIcon,
  llaPlatformLockIcon,
  llaPlatformMailOpenIcon,
  llaPlatformMenuIcon,
  llaPlatformMessageIcon,
  llaPlatformMinusIcon,
  llaPlatformMonetizationOnIcon,
  llaPlatformNotVerifiedIcon,
  llaPlatformPageLandscapeIcon,
  llaPlatformPatrolRefuleIcon,
  llaPlatformPdfIcon,
  llaPlatformPhoneCallHangUpIcon,
  llaPlatformPhoneIcon,
  llaPlatformPlusIcon,
  llaPlatformPriceTagIcon,
  llaPlatformPrinterIcon,
  llaPlatformReceiveMoneyIcon,
  llaPlatformRedoIcon,
  llaPlatformRegionIcon,
  llaPlatformSearchIcon,
  llaPlatformSettingsIcon,
  llaPlatformShopStoreIcon,
  llaPlatformSidebarLeftIcon,
  llaPlatformSidebarMiddleIcon,
  llaPlatformStackIcon,
  llaPlatformStarOutlineIcon,
  llaPlatformStarSolidIcon,
  llaPlatformTagAddIcon,
  llaPlatformTargetIcon,
  llaPlatformTickIcon,
  llaPlatformTrashIcon,
  llaPlatformTreeIcon,
  llaPlatformTrendIcon,
  llaPlatformUserCircleIcon,
  llaPlatformUserPlusIcon,
  llaPlatformUserIcon,
  llaPlatformUsersCircleIcon,
  llaPlatformUsersIcon,
  llaPlatformVerifiedIcon,
  llaPlatformVerticalAlignCenterTriangleIcon,
  llaPlatformWarningDiamondIcon,
  llaPlatformWarningRoundIcon,
  llaPlatformWheelIcon
]
